import styled, { css } from 'styled-components';

// bit of a hack to get line-clamp working avoiding stylelint moaning about it
const lineClamp = ({ maxLines }) =>
  maxLines &&
  css`
    ${`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${maxLines};
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
`}
  `;

export const Text = styled.p`
  overflow-wrap: break-word;
  margin: 0 0 0.35em 0;
  ${({ theme, variant }) => theme.getFluidFont(variant)};
  font-weight: ${({ theme, variant }) => theme.fontSizeWeightMap[variant]};
  line-height: ${({ theme, variant }) => theme.fontSizeLineHeightMap[variant]};
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;

  ${lineClamp}
`;
