import { getFluidSizingFormula } from '@utils/sizing';

const font = 'Circular, sans-serif';

const colors = {
  bluegrey: {
    15: '#202226',
    40: '#5a5e66',
    60: '#8d9199',
    70: '#a8acb3',
    75: '#b6b9bf',
    80: '#c4c7cc',
    90: '#e1e3e6',
    92: '#e9e9eb',
    95: '#f0f1f2',
    97: '#f5f6f7',
  },
  brand: {
    gold: '#c6a22b',
    goldGradient:
      'linear-gradient(45deg, #c6a22b 0%, #c7a22d 28%, #caa434 38%, #d0a73f 45%, #d7aa4d 50%, #d9af54 50%, #e7c979 51%, #f1de97 52%, #f9ecac 53%, #fdf5b9 54%, #fff8bd 54%, #b38c00 100%)',
    green: '#86ff06',
    bluegreyGradient: `linear-gradient(
       180deg,
       rgba(245, 246, 247,100%),
       rgba(255, 255, 255, 0)
      )`,
  },
  grey: {
    0: '#000000',
    10: '#1a1a1a',
    20: '#333333',
    40: '#666666',
    80: '#cccccc',
    85: '#d2d4d9',
    100: '#ffffff',
  },
  hue: {
    blue: '#198cff',
    lightBlue: '#117AF2',
    malibu: '#73B9FF',
    aliceBlue: '#F7FBFF',
    brightBlue: '#c0e0ff',
    lightGrey: '#e0e0e0',
    errorYellow: '#fffff4',
    green: '#73b824',
    darkGreen: '#68A620',
    orange: '#ffaa00',
    yellow: '#f9be26',
    darkOrange: '#ff5500',
    red: '#e70e25',
  },
  transparent: 'rgba(0, 0, 0, 0)',
};

export const spacings = {
  equal: [
    [5, 5],
    [4, 4],
    [3, 3],
    [2, 2],
    [1.5, 1.5],
    [1, 1],
    [0.8, 0.8],
    [2.5, 2.5],
    [3.5, 3.5],
    [10, 10],
    [6, 6],
    [0, 0],
    [2.8, 2.8],
    [-6.1, -6.1],
    [0.6, 0.6],
    [0.2, 0.2],
  ],
  scale: [
    [8, 6], // 1
    [6, 5], // 2
    [5, 4], // 3
    [4, 3.5], // 4
    [3, 2.5], // 5
    [2.5, 2], // 6
    [2, 1.5], // 7
    [1.5, 1], // 8
    [1, 0.5], // 9
    [1.5, 0.5], // 10
    [11, 5], // 11
    [5, 2], // 12
    [20, 10], // 13
    [24.5, 12], // 14
    [12, 8], // 15
    [6, 2.5], // 16
    [6, 8], // 17
    [2, 4], // 18
    [2, 1], // 19
    [8, 5], // 20
    [4, 2], // 21
    [24, 14], // 22
    [1.5, 1.25], // 23
    [10, 8], // 24
  ],
  section: [
    [24, 14],
    [18, 12],
    [14, 10],
    [10, 8],
    [18, 6],
  ],
  widthBased: [
    [8, 2.5],
    [6, 2],
    [4, 2],
    [3, 2],
    [3, 1],
    [2.5, 1],
    [18, 5],
    [12.5, 2.5],
  ],
  heroImage: [
    [48, 30],
    [100, 40],
  ],
  gallery: [
    [11, 5],
    [18, 5],
  ],
};

export const breakpoints = [
  176,
  168,
  160,
  152,
  144,
  136,
  128,
  120,
  112,
  104,
  96,
  88,
  80,
  72,
  64,
  56,
  48,
  40,
  32,
];

const largestBreakpoint = breakpoints[0];
const smallestBreakpoint = breakpoints[breakpoints.length - 1];

const lineHeights = [1.1, 1.2, 1.3, 1.6];

const fontSizes = {
  body1: [1.8, 1.8],
  body2: [1.6, 1.6],
  body3: [1.4, 1.4],
  body4: [1.2, 1.2],
  body5: [2.4, 1.2],
  body6: [3.4, 2.0],
  body7: [2.4, 1.6],
  body8: [2.1, 1.0],
  body9: [3.3, 1.6],
  body10: [2.9, 1.4],
  body11: [1.6, 0.8],
  h1: [6, 3.6],
  h2: [5, 3.3],
  h3: [4.2, 3],
  h4: [3.4, 2.7],
  h5: [2.8, 2.4],
  h6: [2.4, 2.2],
  paragraph1: [2, 2],
  paragraph2: [2, 2],
  subtitle1: [2.8, 2.4],
  subtitle2: [2.4, 2.2],
  mainMenu: [2.7, 1.6],
  stopperCount: [6.2, 3.0],
};

const fontSizeLineHeightMap = {
  body1: lineHeights[1],
  body2: lineHeights[1],
  body3: lineHeights[1],
  body4: lineHeights[1],
  h1: lineHeights[0],
  h2: lineHeights[0],
  h3: lineHeights[0],
  h4: lineHeights[0],
  h5: lineHeights[0],
  h6: lineHeights[0],
  paragraph1: lineHeights[3],
  paragraph2: lineHeights[1],
  subtitle1: lineHeights[2],
  subtitle2: lineHeights[2],
};

const fontSizeWeightMap = {
  body1: 400,
  body2: 400,
  body3: 400,
  body4: 400,
  h1: 700,
  h2: 700,
  h3: 700,
  h4: 700,
  h5: 700,
  h6: 700,
  paragraph1: 400,
  paragraph2: 400,
  subtitle1: 500,
  subtitle2: 500,
  mainMenu: 700,
  stopperCount: 700,
};

const borderRadiuses = {
  circle: '50%',
  'normal.0': 0,
  'normal.1': 0.8,
  'normal.2': 1.2,
  'normal.3': 1.5,
  'normal.4': 0.6,
  pill: 999.9,
};

const shadows = [
  '0 4px 12px 0 rgba(0, 0, 0, 0.12)',
  '0 1px 8px 0 rgba(0, 0, 0, 0.12)',
  '0 0 6px 0 rgba(0,0,0,0.05)',
  '0 4px 20px 0 rgba(0,0,0,0.12)',
  '0 0.4rem 1.2rem 0 rgba(0, 0, 0, 0.12)',
  '0 0 0.6rem 0 rgba(0, 0, 0, 0.05)',
  '0 0.4rem 2rem 0 rgba(0, 0, 0, 0.12)',
  '0 0 2rem 0 rgba(0, 0, 0, 0.8)',
];

const transitions = {
  easings: {
    default: 'ease',
    easeIn: 'ease-in',
    slowStart: 'cubic-bezier(0.82, 0, 0.5, 1)',
    slowEnd: 'cubic-bezier(0.5, 0, 0.18, 1)',
    fastSwipe: 'cubic-bezier(0.05, 0, 0, 1)',
  },
  speeds: {
    default: '0.3s',
    veryFast: '0.15s',
    fast: '0.2s',
    mediumFast: '0.25s',
    medium: '0.3s',
    mediumSlow: '0.4s',
    slow: '0.5s',
    verySlow: '0.7s',
  },
};

const printAttributes = (attrs, value) => attrs.map(attr => `${attr}: ${value};`).join('\n');

function getFluidAttribute(
  attribute,
  sizeMin,
  sizeMax,
  fluidSizingFormula = getFluidSizingFormula(sizeMin, sizeMax),
  unit = 'rem'
) {
  const attrs = Array.isArray(attribute) ? attribute : [attribute];

  return `
    ${printAttributes(attrs, `${sizeMin}${unit}`)}

    @media screen and (min-width: ${(smallestBreakpoint * 10) / 16}em) {
      ${printAttributes(attrs, `${fluidSizingFormula}`)}
    }

    @media screen and (min-width: ${(largestBreakpoint * 10) / 16}em) {
      ${printAttributes(attrs, `${sizeMax}${unit}`)}
    }
  `;
}

function getFluidSpacing(attribute, namespace, widthIdx, negative = false, includedEnv = '') {
  const spacingDesktop = spacings[namespace][widthIdx][0] * (negative ? -1 : 1);
  const spacingMobile = spacings[namespace][widthIdx][1] * (negative ? -1 : 1);
  const maxBreakpoint = breakpoints[0];
  const minBreakpoint = breakpoints[18];
  const fluidSizingFormula = getFluidSizingFormula(
    spacingDesktop,
    spacingMobile,
    minBreakpoint,
    maxBreakpoint,
    'rem',
    includedEnv,
    negative
  );

  return getFluidAttribute(attribute, spacingMobile, spacingDesktop, fluidSizingFormula);
}

function getFluidFont(variant) {
  const fontSizeDesktop = fontSizes[variant][0];
  const fontSizeMobile = fontSizes[variant][1];
  const maxBreakpoint = breakpoints[0];
  const minBreakpoint = breakpoints[18];
  const fluidSizingFormula = getFluidSizingFormula(
    fontSizeDesktop,
    fontSizeMobile,
    minBreakpoint,
    maxBreakpoint
  );

  return getFluidAttribute('font-size', fontSizeMobile, fontSizeDesktop, fluidSizingFormula);
}

export const theme = {
  backgroundColor: '#fff',
  borderRadiuses,
  breakpoints,
  colors,
  font,
  fontSizeLineHeightMap,
  fontSizeWeightMap,
  fontSizes,
  getFluidAttribute,
  getFluidFont,
  getFluidSpacing,
  largestBreakpoint,
  shadows,
  smallestBreakpoint,
  transitions,
};
